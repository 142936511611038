import Navigation from '$components/Navigation'
import NavigationMobile from '$components/NavigationMobile'
import { mediaQueries } from '$theme/legacy'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Favicon from './Favicon'
import Footer, { footerHeight } from './Footer'
import './Layout.css'

const Content = styled.div`
  max-width: ${mediaQueries.DEFAULT_WIDTH};
  padding-top: 0;
  max-width: 100%;
  margin: 0 auto 0 auto;
  height: 100%;
`

type Props = {
  children?: React.ReactNode
  hideRegistration?: boolean
}

function Layout(props: Props) {
  const { children } = props

  const height = `calc(100vh - ${footerHeight})`

  return (
    <React.Fragment>
      <div
        css={css`
          padding: 50px 0;
          min-height: ${height};
          ${mediaQueries.MIN_DEFAULT_MEDIA_QUERY} {
            padding: 62px 0;
          }
        `}
      >
        <Helmet title={'Scope Travel'} />
        <Favicon />
        <Navigation />
        <div
          css={css`
            ${mediaQueries.MIN_DEFAULT_MEDIA_QUERY} {
              display: none;
            }
          `}
        >
          <NavigationMobile />
        </div>

        <Content>{children}</Content>
      </div>
      <Footer />
    </React.Fragment>
  )
}
export default Layout
