import * as React from 'react'
import css from '@emotion/css'
import { Link } from 'gatsby'

import Text from '$components/Text'

import { rhythm } from '$utils/typography'
import logo from './../images/logo-no-text.svg'

import { Grays } from '$theme/colors'
import { HoverCapability, HoverFeature } from './InteractionFeature'

export const footerHeight = `220px`

function Footer() {
  return (
    <div
      css={css`
        height: ${footerHeight};
        width: 100%;
        background-color: ${Grays.Nine};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          max-width: 1280px;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin: 0 auto;
        `}
      >
        {' '}
        <HoverCapability feature={HoverFeature.Hover}>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              > * {
                padding: 10px;
              }
            `}
          >
            <LinkItem to="/contact"> Contact </LinkItem>
            <LinkItem to="/destinations"> All Destinations </LinkItem>
            <LinkItem to="/destinations/asia"> Asia </LinkItem>
            <LinkItem to="/destinations/europe"> Europe </LinkItem>
            <LinkItem to="/destinations/south-america">
              {' '}
              South America{' '}
            </LinkItem>
            <LinkItem to="/destinations/north-america">
              {' '}
              North America{' '}
            </LinkItem>
          </div>
        </HoverCapability>
        <div
          css={css`
            text-align: center;
          `}
        >
          <div>
            <img
              alt="footer-logo"
              src={logo}
              css={css`
                margin: 0 8px;
                padding: 0;
                width: 35px;
                filter: grayscale(1);
              `}
            />
          </div>
          <Text size={Text.Sizes.Small} color={Text.Colors.Primary}>
            website {new Date().getFullYear()} © Scope Travel{' '}
          </Text>
        </div>
      </div>
    </div>
  )
}

function LinkItem({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <Link to={to}>
      <Text weight={Text.Weights.Bold} specialType={Text.SpecialTypes.LinkAlt}>
        {children}
      </Text>
    </Link>
  )
}
export default Footer
