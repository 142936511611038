export enum Grays {
  Zero = '#212529',
  One = '#343A40',
  Three = '#868E96',
  Four = '#ADB5BD',
  Five = '#CED4DA',
  Seven = '#E9ECEF',
  Eight = '##F1F3F5',
  Nine = '#F8F9FA',
  Ten = '#FFF',
}

export enum Greens {
  Zero = '#087F5B',
  Six = '#63E6BE',
  Seven = '#96F2D7',
  Eight = '#C3FAE8',
}

export default {
  Grays,
  Greens,
}
