import { HoverCapability, HoverFeature } from '$components/InteractionFeature'
import Text from '$components/Text'
import { colors } from '$theme/legacy'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import { Link as GatsbyLink } from 'gatsby'
import * as React from 'react'
import {
  animated,
  config,
  useChain,
  useSpring,
  useTransition,
} from 'react-spring'
import DownCaret from './DownCaret'
import { activeStyle, Link, linkStyle } from './index'

const { useState, useRef } = React

const columnCss = css`
  display: flex;
  flex-direction: column;
`

function Animated() {
  // const [checked, updateChecked] = React.useState(false);
  const leaveOpen = false // Use this flag to leave menu open during coding
  const [submenuOpen, updateSubmenu] = useState(leaveOpen || false)

  const itemRef = useRef(null)
  const subItemRef = useRef(null)
  const overlayRef = useRef(null)

  const caretProps = useSpring({
    transform: submenuOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
    config: config.wobbly,
  })

  const overlayProps = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: submenuOpen ? 0.46 : 0,
    },
    config: {
      ...config.default,
      tension: 500,
    },
    ref: overlayRef,
  })

  const itemProps = useSpring({
    from: { height: 0, borderBottomWidth: 0 },
    to: {
      height: submenuOpen ? 237 : 0,
      borderBottomWidth: submenuOpen ? 1 : 0,
    },

    ref: itemRef,
  })

  const transitions = useTransition(
    submenuOpen ? data : [],
    item => {
      return item.key
    },
    {
      ref: subItemRef,
      unique: true,
      trail: 150 / data.length,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    },
  )

  useChain(
    submenuOpen
      ? [itemRef, subItemRef, overlayRef]
      : [overlayRef, subItemRef, itemRef],
    [
      submenuOpen ? 0 : 1.5,
      // Increate else condition to see delay on transition outbound
      submenuOpen ? 0.3 : 0.2,
    ],
  )

  const Caret = () => (
    <span
      css={css`
        margin-left: 10px;
      `}
    >
      <animated.div style={caretProps}>
        <DownCaret />
      </animated.div>
    </span>
  )

  const subMenuWithAnimation = (
    <>
      {' '}
      <animated.div
        style={itemProps}
        css={css`
          position: absolute;
          left: 0;
          top: 62px;
          width: 100%;
          background: ${colors.grays.nine};
          overflow: hidden;
          display: flex;
          justify-content: center;
          border-bottom-style: solid;
          border-bottom-color: ${colors.grays.four};
        `}
      >
        {transitions.map(({ item, key, props }) => (
          <animated.div
            key={key}
            style={props}
            css={css`
              will-change: opacity;
              padding: ${rhythm(1)};
              display: flex;
              & > div {
                flex: 1;
                font-size: 0.83rem;
              }
            `}
          >
            {item.el}
          </animated.div>
        ))}
      </animated.div>
    </>
  )

  function handleGetProps(props) {
    const { isPartiallyCurrent } = props
    return isPartiallyCurrent ? { style: activeStyle } : {}
  }

  return (
    <>
      <animated.div
        style={overlayProps}
        css={css`
          position: absolute;
          left: 0;
          top: 62px;
          width: 100%;
          height: 100vh;
          background: ${colors.grays.zero};
          pointer-events: none; /* Make click throughable */
        `}
      />
      <HoverCapability feature={HoverFeature.None}>
        <Link
          css={[linkStyle]}
          to="/destinations/"
          onClick={e => {
            updateSubmenu(open => !open)
            e.preventDefault()
          }}
          activeStyle={activeStyle}
          getProps={handleGetProps}
        >
          Destinations
          <Caret />
        </Link>
        {subMenuWithAnimation}
      </HoverCapability>
      <HoverCapability feature={HoverFeature.Hover}>
        <div
          onMouseEnter={() => updateSubmenu(_open => true)}
          onMouseLeave={() =>
            updateSubmenu(_open => (leaveOpen ? true : false))
          }
        >
          <Link
            to="/destinations/"
            // onClick={closeNavigation}
            activeStyle={activeStyle}
            getProps={handleGetProps}
          >
            Destinations
            <Caret />
          </Link>
          {subMenuWithAnimation}
        </div>
      </HoverCapability>
    </>
  )
}
const data = [
  {
    key: -1,
    el: (
      <div css={columnCss}>
        {/* <Text size={Text.Sizes.Small}>Canada</Text> */}
        <GatsbyLink to="/destinations">
          <Text
            weight={Text.Weights.Bold}
            specialType={Text.SpecialTypes.LinkAlt}
          >
            All Destinations
          </Text>
        </GatsbyLink>
        {/* <GatsbyLink to="/guides">
					<Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
						All Guides
					</Text>
				</GatsbyLink> */}
      </div>
    ),
  },
  {
    key: 0,
    el: (
      <div css={columnCss}>
        <GatsbyLink to="/destinations/asia">
          <Text
            weight={Text.Weights.Bold}
            specialType={Text.SpecialTypes.LinkAlt}
          >
            Asia
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/asia/cambodia">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Cambodia
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/asia/japan">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Japan
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/asia/philippines">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Philippines
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/asia/thailand">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Thailand
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/asia/vietnam">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Vietnam
          </Text>
        </GatsbyLink>
      </div>
    ),
  },
  {
    key: 1,
    el: (
      <div css={columnCss}>
        <GatsbyLink to="/destinations/europe">
          <Text
            weight={Text.Weights.Bold}
            specialType={Text.SpecialTypes.LinkAlt}
          >
            Europe
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/europe/austria">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Austria
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/europe/bosnia-and-herzegovina">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Bosnia
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/europe/croatia">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Croatia
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/europe/hungary">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Hungary
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/europe/netherlands">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Netherlands
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/europe/spain">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Spain
          </Text>
        </GatsbyLink>
      </div>
    ),
  },
  {
    key: 2,
    el: (
      <div css={columnCss}>
        <GatsbyLink to="/destinations/north-america">
          <Text
            weight={Text.Weights.Bold}
            specialType={Text.SpecialTypes.LinkAlt}
          >
            North America
          </Text>
          {/* <Text size={Text.Sizes.Small}>Canada</Text> */}
        </GatsbyLink>
        <GatsbyLink to="/destinations/north-america/cuba">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Cuba
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/north-america/united-states">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            United States
          </Text>
        </GatsbyLink>
      </div>
    ),
  },
  {
    key: 3,
    el: (
      <div css={columnCss}>
        <GatsbyLink to="/destinations/south-america">
          <Text
            weight={Text.Weights.Bold}
            specialType={Text.SpecialTypes.LinkAlt}
          >
            South America
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/south-america/argentina">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Argentina
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/south-america/chile">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Chile
          </Text>
        </GatsbyLink>
        <GatsbyLink to="/destinations/south-america/peru">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.LinkAlt}>
            Peru
          </Text>
        </GatsbyLink>
      </div>
    ),
  },
]

export default Animated
