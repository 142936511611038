/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'
import { colors, fonts } from '$theme/legacy'
import { Grays, Greens } from '$theme/colors'

export enum Family {
  Primary = 'Roboto, sans-serif',
}
export enum Weight {
  Normal = 400,
  Bold = 500,
  Bolder = 700,
}
export enum Size {
  ExtraLarge = '3.333rem',
  Large = '2rem',
  Normal = '1rem',
  Small = '.88889rem',
  ExtraSmall = '.83333rem',
}
export enum Style {
  Italic = 'italic',
}

export enum Color {
  Primary = Grays.Zero,
  PrimaryLight = Grays.One,
  PrimaryLighter = Grays.Five,
  PrimaryColored = Greens.Zero,
  PrimaryInverse = Grays.Ten,
}

export enum SpecialType {
  Link = 'link',
  LinkAlt = 'link-alt',
}

export enum Spacing {
  Small = '2px',
}

function resolveSpecialType(type: SpecialType) {
  switch (type) {
    case SpecialType.Link: {
      return css`
        color: ${colors.greens.zero};
        text-decoration: none !important;
        font-weight: ${Weight.Bold};
        display: inline-block; /* So that span won't inherit styles on surrounding anchor tag */
      `
    }
    case SpecialType.LinkAlt: {
      return css`
        color: ${colors.grays.zero};
        text-decoration: none !important;

        display: inline-block; /* So that span won't inherit styles on surrounding anchor tag */
        &:hover {
          color: ${colors.greens.zero};
        }
      `
    }
    default: {
      return null
    }
  }
}

type Props = {
  children?: React.ReactNode
  color?: Color
  family?: Family
  weight?: Weight
  size?: Size
  spacing?: Spacing
  specialType?: SpecialType
  style?: Style
  className?: string
}

function Text(props: Props) {
  const {
    children,
    color = Color.Primary,
    family = Family.Primary,
    weight = Weight.Normal,
    size = Size.Normal,
    spacing = null,
    specialType = null,
    style = null,
  } = props

  return (
    <span
      css={[
        css`
          color: ${color};
          font-family: ${family};
          font-weight: ${weight};
          font-size: ${size};
          letter-spacing: ${spacing};
          font-style: ${style === Style.Italic ? 'italic' : 'normal'};
        `,
        specialType ? resolveSpecialType(specialType) : null,
      ]}
    >
      {children}
    </span>
  )
}

Text.Colors = Color
Text.Families = Family
Text.Weights = Weight
Text.Sizes = Size
Text.Styles = Style
Text.SpecialTypes = SpecialType
Text.Spacings = Spacing

export default Text
