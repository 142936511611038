import typography from '../utils/typography'

export const colors = {
  primary: `#2c3077`,
  secondary: `#13A466`,
  //secondary: '#26C281',
  darker: `#2d2d2d`,
  dark: `#373737`,
  lightDark: '#a9a9a9',
  grays: {
    zero: '#212529',
    one: '#343A40',
    three: '#868E96',
    four: '#ADB5BD',
    five: '#CED4DA',
    nine: '#F8F9FA',
  },
  greens: {
    zero: '#087F5B',
    seven: '#96F2D7',
  },
}

export const fonts = {
  primary: 'Roboto, sans-serif',
  secondary: 'Roboto, sans-serif',
  // secondary: 'Playfair Display, serif',
}

export const mediaQueries = {
  LARGER_DISPLAY_WIDTH: '1600px',
  LARGE_DISPLAY_WIDTH: '1280px',
  DEFAULT_WIDTH: '980px',
  TABLET_WIDTH: '768px',
  MOBILE_WIDTH: '480px',
  LARGER_DISPLAY_MEDIA_QUERY: '@media only screen and (max-width:1600px)',
  LARGE_DISPLAY_MEDIA_QUERY: '@media only screen and (max-width:1280px)',
  DEFAULT_MEDIA_QUERY: '@media only screen and (max-width:1024px)',
  TABLET_MEDIA_QUERY: '@media only screen and (max-width:768px)',
  MOBILE_MEDIA_QUERY: '@media only screen and (max-width:480px)',
  MIN_LARGER_DISPLAY_MEDIA_QUERY: '@media (min-width:1600px)',
  MIN_LARGE_DISPLAY_MEDIA_QUERY: '@media (min-width:1280px)',
  MIN_DEFAULT_MEDIA_QUERY: '@media (min-width:1024px)',
  MIN_TABLET_MEDIA_QUERY: '@media (min-width:768px)',
  MIN_MOBILE_MEDIA_QUERY: '@media (min-width:480px)',
}

export const aspectRatios = {
  '1:1': '100%',
  '1:2': '200%',
  '2:1': '50%',
  '3:2': '66.6666%',
  '4:3': '75%',
  '16:9': '56.25%',
  '21:9': '42.8571%',
}

export const forms = {
  fieldset: {
    display: 'flex',
    border: 0,
    width: '100%',
    '& > label': {
      color: 'white',
      fontFamily: fonts.primary,
      fontWeight: '400',
      fontSize: '.8rem',
    },
    '& input[type=text], input[type=email], textarea': {
      width: '100%',
      boxSizing: 'border-box',
      padding: '.9rem',
      fontFamily: fonts.primary,
      fontWeight: '300',
      border: 0,
      // backgroundColor: tinycolor(colors.primary)
      // 	.lighten(10)
      // 	.toString(),
      color: 'white',
    },
    '& input[type=submit]': {
      backgroundColor: colors.primary,
    },
  },
}

export const dateFormats = {
  primary: 'MM.DD.YY',
}
