import * as React from 'react'
import css from '@emotion/css'
import { colors } from '$theme/legacy'
type HamburgerProps = {
  onClick: () => void
}

function Hamburger(props: HamburgerProps) {
  const { onClick } = props

  const baseCss = css`
    stroke: ${colors.grays.four};
    stroke-width: 2px;
    stroke-linecap: round;
  `

  return (
    <button
      onClick={onClick}
      css={css`
        display: flex;
        flex-direction: column;
        appearance: none;
        border: 0;
        background: transparent;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
      `}
    >
      <svg
        css={css`
          width: 39px;
          height: 16px;
        `}
      >
        <line css={baseCss} x1="1" x2="14" y1="1" y2="1" />{' '}
        <line css={baseCss} x1="1" x2="26" y1="8" y2="8" />{' '}
        <line css={baseCss} x1="1" x2="19" y1="15" y2="15" />{' '}
      </svg>
    </button>
  )
}

export function CloseX(props: HamburgerProps) {
  const { onClick } = props

  const baseCss = css`
    stroke: ${colors.grays.four};
    stroke-width: 2px;
    stroke-linecap: round;
  `

  return (
    <button
      onClick={onClick}
      css={css`
        display: flex;
        flex-direction: column;
        appearance: none;
        border: 0;
        background: transparent;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
      `}
    >
      <svg
        css={css`
          width: 17px;
          height: 17px;
        `}
      >
        <line css={baseCss} x1="1" x2="16" y1="1" y2="16" />{' '}
        <line css={baseCss} x1="1" x2="16" y1="16" y2="1" />{' '}
      </svg>
    </button>
  )
}

export default Hamburger
