import * as React from 'react'
import { useSpring, animated, config } from 'react-spring'
import css from '@emotion/css'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { colors } from '$theme/legacy'
import Hamburger, { CloseX } from '$components/Hamburger'
import Text from '$components/Text'
import logo from '../images/nav-menu-logo.svg'
import { Query } from 'graphqlTypes'

function NavigationItem(props: { children: React.ReactNode }) {
  return (
    <div
      css={css`
        margin-bottom: 1rem;
        padding: 0 2rem;
      `}
    >
      <Text
        size={Text.Sizes.Large}
        weight={Text.Weights.Bolder}
        specialType={Text.SpecialTypes.LinkAlt}
      >
        {props.children}
      </Text>
    </div>
  )
}

function NavigationSubItem(props: { children: React.ReactNode }) {
  return (
    <Text
      size={Text.Sizes.Normal}
      weight={Text.Weights.Bold}
      specialType={Text.SpecialTypes.LinkAlt}
    >
      {props.children}
    </Text>
  )
}

type NavigationMobileBarProps = {
  onHamburgerClick: () => void
}

function NavigationMobileBar(props: NavigationMobileBarProps) {
  const { onHamburgerClick } = props

  return (
    <nav
      css={css`
        position: fixed;
        top: 0;
        z-index: 999998;
        height: 50px;
        width: 100%;
        border-bottom: 1px solid ${colors.grays.four};
        background: white;
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          position: absolute;
          height: 100%;
        `}
      >
        <Hamburger onClick={onHamburgerClick} />
      </div>
      <div
        css={css`
          display: flex;
          flex: 1;
          justify-content: center;
        `}
      >
        <Link
          to="/"
          css={css`
            line-height: 1;
          `}
        >
          <img
            src={logo}
            css={css`
              margin: 0;
              /* transform: translateX(-25px); */
            `}
          />
        </Link>
      </div>
    </nav>
  )
}

export function NavigationMobile(props) {
  const { backgroundImage } = props
  const [open, set] = React.useState(false)

  const toggleDrawer = () => set(open => !open)

  const navProps = useSpring({
    transform: open ? 'translateX(0px)' : 'translateX(-315px)',
  })

  return (
    <>
      <NavigationMobileBar onHamburgerClick={toggleDrawer} />
      <animated.div
        style={navProps}
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 999998;
          display: flex;
          z-index: 999999;
          flex-direction: column;
          height: 100vh;
          width: 315px;
          background: ${colors.grays.nine};
        `}
      >
        <div
          css={css`
            position: absolute;
            height: 100vh;
            width: 315px;
            z-index: -1;
            opacity: 0.25;
          `}
        >
          <Img
            fluid={backgroundImage}
            css={css`
              height: 100vh;
              overflow: 'none';
            `}
          />
        </div>
        <div
          css={css`
            height: 50px;
            width: 100%;

            display: flex;
            justify-content: flex-end;
            align-items: center;
          `}
        >
          <CloseX onClick={toggleDrawer} />
        </div>
        <Link to="/">
          <NavigationItem>Home</NavigationItem>
        </Link>
        <Link to="/destinations">
          <NavigationItem>Destinations</NavigationItem>
        </Link>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
            margin-bottom: 1rem;
            padding: 0 2rem;
          `}
        >
          <Link to="/destinations">
            {' '}
            <NavigationSubItem>All Destinations</NavigationSubItem>{' '}
          </Link>
          <Link to="/destinations/asia">
            <NavigationSubItem>Asia</NavigationSubItem>
          </Link>
          <Link to="/destinations/europe">
            {' '}
            <NavigationSubItem>Europe</NavigationSubItem>{' '}
          </Link>
          <Link to="/destinations/north-america">
            {' '}
            <NavigationSubItem>North America</NavigationSubItem>{' '}
          </Link>
          <Link to="/destinations/south-america">
            {' '}
            <NavigationSubItem>South America</NavigationSubItem>{' '}
          </Link>
        </div>
        {/* <Link to="/about">
          {' '}
          <NavigationItem>About</NavigationItem>{' '}
        </Link> */}
        <Link to="/contact">
          {' '}
          <NavigationItem>Contact</NavigationItem>{' '}
        </Link>
      </animated.div>
    </>
  )
}

const query = graphql`
  query {
    file(relativePath: { eq: "mobile-nav-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

function NavigationMobileWithData() {
  const data: Query = useStaticQuery(query)

  return data && data.file && data.file.childImageSharp ? (
    <NavigationMobile backgroundImage={data.file.childImageSharp.fluid} />
  ) : null
}

export default NavigationMobileWithData
