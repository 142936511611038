import * as React from 'react'
import downCaret from './down-caret.svg'
import css from '@emotion/css'
const DownCaret = () => (
  <img
    alt="navigation-down-caret"
    src={downCaret}
    css={css`
      width: 10px;
      height: 10px;
      margin: 0;
    `}
  />
)
export default DownCaret
