import * as React from 'react'
import { colors, fonts, mediaQueries } from '$theme/legacy'
import { Link as GatsbyLink } from 'gatsby'
import css from '@emotion/css'
import styled from '@emotion/styled'
import logo from '../../images/nav-menu-logo2.svg'
import DestinationsAnimated from './DestinationsAnimated'
import { Grays, Greens } from '$theme/colors'

export const linkStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  padding: 1rem;
  color: ${Grays.One};
  text-decoration: none;
  transition: all 0.3s ease-out;
  font-weight: 500;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${colors.greens.seven};
  }
`

export const Link = styled(GatsbyLink)(linkStyle)

const Input = styled.input`
  display: none;
`

const LinkContainer = styled.div`
  display: none;
  z-index: 999;
  ${mediaQueries.MIN_DEFAULT_MEDIA_QUERY} {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }
`

const NavigationContainer = styled.nav`
	display:none;
	position:fixed;
	top: 0;
	z-index: 99999;
	background: white;
	/* background: ${colors.grays.zero}; */
	width: 100%;	
	flex-direction: row;
	justify-content:center;
	height: 62px;
	border-bottom:1px solid ${colors.grays.four};	
	
	& > ${Input}:checked + ${LinkContainer} {
		position: fixed;
		top: 62px;
		display: flex;
		flex-direction: column;
		background-color: ${colors.grays.five};
		width: 100%;
	}
	
	${mediaQueries.MIN_DEFAULT_MEDIA_QUERY} {
		display: flex;
		padding: 0 2.22222rem;
	}
`

const LogoLink = styled(Link)`
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.div`
  width: 200px;
  height: 100%;
`

const Hambuger = styled.label`
  display: flex;
  align-items: center;
  flex: 0;
  padding: 15px;
  color: white;
  cursor: pointer;
  ${mediaQueries.MIN_DEFAULT_MEDIA_QUERY} {
    display: none;
  }
`

export const activeStyle = { color: Grays.Zero }

function Navigation() {
  return (
    <NavigationContainer>
      <div
        css={css`
          width: 1280px;
          display: flex;
        `}
      >
        <Hambuger htmlFor="gfu-toggle">&#9776;</Hambuger>
        <LogoLink to="/">
          <Logo>
            <img src={logo} alt="Scope Travel Logo" />
          </Logo>
        </LogoLink>

        <LinkContainer>
          <DestinationsAnimated />

          <Link to="/contact" style={{}} activeStyle={activeStyle}>
            Contact
          </Link>
        </LinkContainer>
      </div>
    </NavigationContainer>
  )
}

export default Navigation
